import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { GraphQlClient } from 'graphql/base';
import { ConnectBlog } from '@silkpwa/module/react-component/connect-blog';
import { Loading } from 'ui/component/checkout/steps/loading';
import BlogViewContent from './blog-view-content';

const BlogViewPage = () => (
    <ApolloProvider client={GraphQlClient}>
        <ConnectBlog fallback={Loading}>
            {(blogId: number) => <BlogViewContent blogId={blogId} />}
        </ConnectBlog>
    </ApolloProvider>
);

export default BlogViewPage;
