import React from 'react';
import fb from 'ui/component/svg/facebook/fb.svg';
import twitter from 'ui/component/svg/twitter/x.svg';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';

import styles from './style.css';

interface SocialProps {
    fbShareLink: string;
    twitterShareLink: string;
}

const SocialShare: React.FC<SocialProps> = ({ fbShareLink, twitterShareLink }) => (
    <>
        <div className={styles.shareCnt}>
            <span>SHARE</span>
            <AccessibleButton
                tag="a"
                target="_blank"
                href={fbShareLink}
                action={() => { /** Not needed this here */ }}
                className={styles.linkCnt}
            >
                <span className={styles.shareBtnCnt} data-link={fbShareLink}>
                    <img
                        src={fb}
                        alt="facebook"
                        className={styles.shareBtnImgCnt}
                    />
                </span>
            </AccessibleButton>
            <AccessibleButton
                tag="a"
                target="_blank"
                href={twitterShareLink}
                action={() => { /** Not needed this here  */ }}
                className={styles.linkCnt}
            >
                <span className={styles.shareBtnCnt} data-link={twitterShareLink}>
                    <img
                        src={twitter}
                        alt="twitter"
                        className={styles.shareBtnImgCnt}
                    />
                </span>
            </AccessibleButton>
        </div>
    </>
);

export default SocialShare;
